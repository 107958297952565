"use client";

import { v4 } from "uuid";
import useAuthenticatedPublicRoles from "./(authentication)/_hooks/useAuthenticatedPublicRoles";
import { Body } from "./_components/Body";
import Footer from "./_components/Footer";
import { base64ContentOverride } from "./base64ContentOverride";
import Writer from "./workspaces/[campaign_id]/(editor)/_components/Writer";
import ToolbarProvider from "./workspaces/[campaign_id]/(editor)/_contexts/ToolbarProvider";

export default function LandingPageContent() {
   const { publicRole } = useAuthenticatedPublicRoles();
   return (
      <>
         <title>Potluck Action Home</title>
         <ToolbarProvider>
            {/* <FocusItemProvider editable={false} token={undefined} role={publicRole}> */}
            <Body supportWidget={false} mainVariant={"none"} includeDefaultRoleSelector={false} maxWidth={"none"} margin={"none"} marginTop={"none"} contentVariant={"none"}>
               <Writer
                  orientationOverride={false}
                  autoFocus={false}
                  editable={false}
                  documentId={v4()}
                  activityType={undefined}
                  expectAuthentication={false}
                  shouldConnectHocus={false}
                  contentOverride={base64ContentOverride}
                  token={undefined}
                  saveContentVersion={false}
                  addTopPriorityExtensions={[]}
                  collaboratorName={undefined}
               />
               <Footer mark flag />
            </Body>
            {/* </FocusItemProvider> */}
         </ToolbarProvider>
      </>
   );
}
