"use client";
import { Extension } from "@tiptap/core";
import { Editor, EditorContent } from "@tiptap/react";
import LoadingPageDefault from "app/_components_v2/feedback/LoadingPageDefault";
import ILog from "app/_lib/global/Log";
import { ActivityType } from "app/_types/Schema";
import { useContext } from "react";
import { ToolbarSetterContext, ToolbarToggleSetterContext } from "../_contexts/ToolbarProvider";
import useTiptapEditor from "../_hooks/useTiptapEditor";

import { HocuspocusProvider } from "@hocuspocus/provider";
import { UserIdContext } from "app/(platform)/(authentication)/_contexts/AuthenticatedSessionProvider";
import _DivUNSAFE from "app/_components_v2/layout/_DivUNSAFE";
import { useLocalStorage } from "usehooks-ts";
import * as Y from "yjs";
import { ActivitySpecNodeAttributesInitialization } from "./nodes/defaultNodeAttributes";
export interface WriterProps {
   expectAuthentication?: boolean;
   autoFocus: boolean;
   shouldConnectHocus?: boolean;
   documentId: string;
   token: string | undefined;
   activityType?: ActivityType | "edition" | "transcript";
   editable: boolean;
   SpecNodeAttributes?: ActivitySpecNodeAttributesInitialization | undefined;
   contentOverride: string | null | undefined;
   ToolbarSlot?: ({ token, editorInstance, provider, yDoc }: { token: string | undefined; editorInstance: Editor; provider: HocuspocusProvider | null; yDoc: Y.Doc | null }) => React.ReactNode;
   shouldSetEditor?: boolean;
   orientationOverride?: boolean;
   saveContentVersion: boolean;
   editorClassAttributes?: string;
   includeCommandItems?: boolean;
   addTopPriorityExtensions: Extension[];
   collaboratorName: string | undefined;
   shouldSetEditorOnCreate?: boolean;
   onFocusProvider?: (provider: HocuspocusProvider) => void;
}

export function Writer({
   autoFocus = false,

   shouldConnectHocus = true,
   documentId,
   token,
   activityType,
   editable,
   SpecNodeAttributes,
   expectAuthentication = true,
   contentOverride,
   ToolbarSlot,
   orientationOverride,
   saveContentVersion = false,
   includeCommandItems = true,
   ...props
}: WriterProps) {
   const { userId } = useContext(UserIdContext);
   const { editorInstance, provider, yDoc } = useTiptapEditor({
      autoFocus,
      shouldConnectHocus,
      documentId,
      editable,
      token,
      activityType,
      SpecNodeAttributes,
      expectAuthentication,
      contentOverride,
      shouldSetEditor: true,
      shouldSetEditorOnCreate: true,
      saveContentVersion,
      userId,
      includeCommandItems,
      ...props
   });

   if (editorInstance) {
      return (
         <_DivUNSAFE id="writer-main" className={" bg-slate-50  flex grow flex-col "}>
            {ToolbarSlot && ToolbarSlot({ token, editorInstance, provider, yDoc })}
            <_DivUNSAFE
               data-testid={`${activityType}-writer`}
               id={"writer-focus-container"}
               className="flex  place-content-center h-full"
               onClick={() => {
                  const focused = editorInstance?.isFocused;
                  // if (e.target !== e.currentTarget) return;
                  if (focused) return;
                  ILog.v("Writer_onClick", { focused });
                  // editorInstance?.chain().focus().run();
               }}
            >
               <IEditorContent editable={editable} editorInstance={editorInstance} finalDocumentId={documentId} orientationOverride={orientationOverride} />
            </_DivUNSAFE>
         </_DivUNSAFE>
      );
   }
   return <LoadingPageDefault />;
}

function IEditorContent({
   editorInstance,
   finalDocumentId,
   editable,
   orientationOverride
}: {
   editorInstance: Editor;
   finalDocumentId: string | null | undefined;
   editable: boolean;
   orientationOverride?: boolean;
}) {
   const { invalidateToolbarState } = useContext(ToolbarToggleSetterContext);
   const {} = useContext(ToolbarSetterContext);
   const [isPortrait] = useLocalStorage("orientation", true);
   const finalPortrait = orientationOverride !== false && isPortrait;
   //@ts-expect-error
   const instanceId = editorInstance?.options?.editorProps?.attributes?.instanceId;
   return (
      <EditorContent
         // Warning: contentEditable creates the focus/blur issue.
         id="editor-content"
         data-testid={`editor-content`}
         data-editor-editable={editorInstance.isEditable}
         key={`${instanceId}-editor-content`}
         // className=" editor-content mt-12 p-12 bg-white max-w-[816px] w-full outline-hidden"
         className={`mt-12 p-12 bg-white ${finalPortrait ? "max-w-[816px]" : "max-w-[1056px]"} w-full outline-hidden border-gray-300`}
         editor={editorInstance}
         autoFocus={false}
         // scrolling="auto"
         // contentEditable={editable}
         onClick={() => {}}
         onMouseDown={() => {
            invalidateToolbarState();
            const focused = editorInstance?.isFocused;
            // if (e.target !== e.currentTarget) return;
            if (focused) return;
            ILog.v("Writer_onClick", { focused });
            editorInstance?.chain().focus().run();
         }}
         onDrop={(props) => {
            ILog.v("EditorContent_onDrop_Writer", { props });
         }}
         onChange={() => {
            // invalidateLabelContainerState();
         }}
         onPaste={(e) => {
            ILog.v("EditorContent_onPaste_Writer", { e });
         }}
      />
   );
}

export default Writer;
